import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1258.000000 1280.000000" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" >
<path d="M1878 12015 c-27 -24 -48 -47 -48 -51 0 -4 38 -45 85 -92 47 -46 85
-89 85 -95 0 -6 2 -8 5 -5 3 3 51 -40 106 -94 55 -55 103 -97 107 -94 4 4 91
90 194 193 l188 187 -47 48 -47 48 -291 0 -290 -1 -47 -44z"/>
<path d="M441 12023 c2 -24 2 -25 6 -5 l4 22 315 0 c208 0 313 -3 309 -10 -3
-5 -2 -10 3 -10 6 0 12 7 16 15 5 13 -35 15 -324 15 l-330 0 1 -27z"/>
<path d="M970 12021 c0 -11 -231 -241 -242 -241 -4 0 -8 -4 -8 -8 0 -5 -20
-29 -44 -54 -34 -36 -47 -44 -57 -35 -9 9 -9 7 1 -6 12 -16 6 -25 -61 -93
l-73 -74 254 -255 255 -255 258 257 258 257 -232 232 c-157 157 -229 236 -224
244 5 8 4 11 -3 6 -6 -3 -16 3 -22 14 -11 20 -60 29 -60 11z"/>
<path d="M1710 11933 c-13 -15 -12 -16 4 -10 10 4 20 7 22 7 2 0 4 5 4 10 0
15 -13 12 -30 -7z"/>
<path d="M442 11885 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M1790 11875 c13 -14 26 -25 28 -25 3 0 -5 11 -18 25 -13 14 -26 25
-28 25 -3 0 5 -11 18 -25z"/>
<path d="M1650 11869 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z"/>
<path d="M460 11836 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"/>
<path d="M1586 11803 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M1870 11795 c13 -14 26 -25 28 -25 3 0 -5 11 -18 25 -13 14 -26 25
-28 25 -3 0 5 -11 18 -25z"/>
<path d="M505 11790 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M1550 11770 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M545 11750 c10 -11 20 -20 23 -20 3 0 -3 9 -13 20 -10 11 -20 20 -23
20 -3 0 3 -9 13 -20z"/>
<path d="M1488 11708 l-27 -28 30 -29 c33 -34 40 -29 7 6 l-21 23 23 25 c13
14 22 26 19 28 -2 1 -16 -10 -31 -25z"/>
<path d="M585 11710 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M1926 11705 c4 -8 11 -15 16 -15 6 0 5 6 -2 15 -7 8 -14 15 -16 15
-2 0 -1 -7 2 -15z"/>
<path d="M1975 11690 c10 -11 20 -20 23 -20 3 0 -3 9 -13 20 -10 11 -20 20
-23 20 -3 0 3 -9 13 -20z"/>
<path d="M1949 11643 l-24 -28 28 24 c25 23 32 31 24 31 -2 0 -14 -12 -28 -27z"/>
<path d="M1568 11572 c15 -15 29 -26 31 -25 6 4 -41 53 -50 53 -4 0 4 -13 19
-28z"/>
<path d="M1869 11563 l-24 -28 25 21 c14 12 32 24 40 27 13 5 13 6 -1 6 -8 1
-26 -11 -40 -26z"/>
<path d="M1627 11509 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M1789 11483 l-24 -28 28 24 c25 23 32 31 24 31 -2 0 -14 -12 -28 -27z"/>
<path d="M1715 11424 c9 -9 19 -13 23 -10 3 4 0 11 -8 16 -24 15 -33 11 -15
-6z"/>
<path d="M4030 11192 c-50 -50 -37 -128 27 -161 14 -7 94 -11 222 -11 l201 0
22 -42 23 -43 3 -1287 2 -1287 188 -142 c103 -78 200 -153 215 -166 16 -12 33
-23 38 -23 5 0 9 569 9 1446 l0 1445 22 51 21 50 571 -5 c571 -5 768 -15 1046
-52 332 -43 581 -105 859 -212 506 -194 935 -539 1236 -993 229 -345 353 -666
430 -1110 59 -346 59 -857 0 -1203 -120 -692 -487 -1335 -994 -1741 -63 -50
-117 -90 -120 -89 -3 2 -9 89 -12 195 -7 185 -27 329 -58 411 -19 49 -13 67
44 130 318 355 491 845 526 1480 46 862 -172 1539 -642 1993 -194 187 -406
323 -704 451 -215 93 -504 165 -818 203 -219 26 -676 45 -722 30 -97 -32 -99
-159 -2 -188 17 -6 145 -13 282 -16 572 -13 992 -105 1363 -299 585 -304 902
-767 1014 -1481 20 -125 22 -179 23 -481 0 -350 -6 -428 -47 -645 -55 -289
-144 -519 -281 -726 -67 -102 -132 -183 -139 -174 -2 3 -23 37 -46 76 -103
177 -279 379 -367 420 -106 50 -226 17 -290 -81 -23 -36 -26 -48 -23 -111 3
-59 8 -77 31 -108 16 -20 47 -49 69 -63 34 -23 51 -27 112 -28 58 0 79 5 116
25 62 35 110 108 110 168 l0 45 21 -19 c52 -46 177 -227 221 -318 l23 -49
-109 -103 c-60 -57 -113 -104 -117 -104 -4 0 -14 -7 -23 -15 -33 -33 -205
-140 -314 -195 -164 -82 -269 -122 -442 -168 -140 -37 -123 -43 -160 51 -131
328 -303 581 -581 853 -127 123 -259 231 -994 808 -104 82 -244 192 -310 245
-247 196 -362 287 -399 316 -254 197 -347 289 -425 419 -93 156 -131 299 -131
494 0 249 71 474 201 644 54 69 170 180 225 215 l44 28 0 164 0 163 -27 -6
c-44 -11 -212 -95 -278 -139 -244 -163 -447 -422 -559 -712 -9 -22 -29 -92
-45 -155 -91 -359 -60 -734 83 -1018 97 -192 210 -316 486 -533 58 -45 164
-129 235 -185 72 -57 240 -190 375 -296 135 -107 290 -229 345 -272 55 -44
134 -106 175 -139 41 -32 84 -66 95 -75 12 -10 50 -39 85 -67 36 -27 67 -52
70 -55 3 -3 25 -21 50 -40 215 -164 396 -350 518 -530 65 -97 154 -272 144
-282 -5 -5 -172 -11 -373 -14 -200 -3 -376 -10 -390 -15 -61 -23 -74 -112 -23
-160 l26 -24 423 2 422 1 16 -56 c32 -112 47 -216 47 -328 0 -63 -1 -115 -2
-115 -2 -1 -281 -5 -621 -8 l-617 -6 0 771 0 772 -211 173 c-117 96 -218 180
-225 187 -12 11 -14 -139 -14 -942 l0 -956 -165 0 -165 0 0 151 c0 91 -4 159
-11 172 -32 63 -106 76 -158 28 l-31 -29 0 -261 0 -261 266 0 266 0 -4 -222
c-3 -202 -5 -227 -24 -268 -41 -88 -142 -161 -241 -176 -27 -4 -43 -11 -43
-20 0 -12 288 -14 1910 -14 l1910 0 0 608 0 607 81 54 c209 140 460 375 614
575 17 22 39 51 51 65 36 44 138 201 199 305 180 308 323 709 385 1081 38 232
53 689 30 940 -12 129 -47 385 -60 435 -5 19 -21 82 -35 140 -186 762 -683
1421 -1360 1807 -292 167 -703 305 -1105 372 -413 69 -517 73 -1713 78 l-1067
5 -30 -30z m3937 -5040 c31 -134 46 -299 39 -430 -4 -62 -11 -124 -16 -137
-19 -51 -368 -232 -610 -316 -155 -55 -481 -139 -535 -139 -12 0 -15 13 -15
58 0 80 -18 260 -36 356 -7 43 -14 84 -14 90 0 7 34 20 78 31 287 67 620 223
860 402 72 54 194 161 201 176 10 24 28 -10 48 -91z m-77 -943 c-56 -132 -144
-284 -213 -366 -301 -362 -648 -561 -1119 -644 -41 -8 -326 -13 -820 -16
l-758 -4 0 335 0 336 559 0 c307 0 580 3 606 7 l48 6 -6 -44 c-12 -85 -68
-259 -124 -386 -4 -10 61 -13 305 -13 l310 0 20 48 c45 107 99 301 109 395 5
48 9 58 27 61 166 29 231 43 341 73 236 62 443 141 650 245 50 25 91 45 93 43
2 -2 -11 -36 -28 -76z"/>
<path d="M740 10524 l-205 -205 24 -20 c30 -24 34 -24 62 6 13 13 28 25 32 25
4 0 67 -59 140 -131 l131 -132 -32 -27 c-18 -14 -32 -31 -32 -36 0 -13 73 -84
85 -84 6 0 100 90 210 200 l199 199 -79 83 c-44 46 -136 138 -205 205 l-125
123 -205 -206z"/>
<path d="M5180 10215 c0 -100 3 -137 13 -139 236 -70 372 -144 496 -268 144
-145 223 -317 238 -520 l6 -88 44 0 44 0 -6 378 c-3 207 -8 450 -11 540 l-6
162 -178 0 c-119 0 -204 5 -257 15 -77 16 -345 55 -370 55 -10 0 -13 -30 -13
-135z"/>
<path d="M520 10196 l-35 -41 32 25 c28 22 49 49 41 55 -2 1 -19 -17 -38 -39z"/>
<path d="M456 10131 c-10 -11 -16 -28 -13 -38 4 -17 5 -17 6 1 0 11 8 28 18
38 9 10 14 18 11 18 -3 0 -13 -9 -22 -19z"/>
<path d="M440 10053 c0 -5 5 -15 10 -23 8 -12 10 -11 10 8 0 12 -4 22 -10 22
-5 0 -10 -3 -10 -7z"/>
<path d="M545 9912 c49 -51 91 -92 93 -92 8 0 -56 68 -118 125 -47 43 -41 34
25 -33z"/>
<path d="M817 9975 c-15 -14 -24 -25 -19 -25 5 0 19 11 32 25 30 32 23 32 -13
0z"/>
<path d="M738 9895 c-21 -19 -34 -35 -30 -35 5 0 23 16 42 35 44 45 36 45 -12
0z"/>
<path d="M666 9825 c-11 -8 -17 -17 -14 -20 2 -3 13 4 23 15 21 23 18 25 -9 5z"/>
<path d="M3244 6437 c-121 -131 -255 -322 -337 -482 -78 -151 -169 -440 -202
-642 -50 -301 21 -718 175 -1041 251 -525 780 -886 1450 -987 148 -23 476 -31
641 -15 479 44 881 200 1179 459 77 67 230 222 230 233 0 4 -150 8 -333 8
l-333 0 -79 -58 c-275 -200 -554 -300 -935 -333 -382 -33 -755 50 -1049 234
-365 228 -602 536 -696 906 -39 155 -48 255 -42 470 6 253 37 423 114 631 70
189 172 381 282 529 l54 74 -34 28 c-19 16 -37 29 -40 29 -3 0 -23 -19 -45
-43z"/>
<path d="M4029 6381 l-29 -29 0 -207 0 -207 34 -34 c25 -24 43 -34 66 -34 23
0 42 10 66 34 l34 34 0 196 c0 208 -6 243 -49 266 -39 20 -91 12 -122 -19z"/>
<path d="M3395 3007 c-3 -7 -4 -152 -3 -322 l3 -310 225 0 225 0 3 53 3 52
-165 0 -166 0 0 270 0 270 -60 0 c-40 0 -62 -4 -65 -13z"/>
<path d="M5010 2965 l0 -55 60 0 60 0 0 55 0 55 -60 0 -60 0 0 -55z"/>
<path d="M6630 2695 l0 -325 55 0 55 0 0 36 0 36 35 -31 c98 -86 224 -53 285
74 28 58 27 189 -1 255 -27 64 -81 102 -152 108 -51 4 -118 -15 -132 -38 -18
-29 -25 0 -25 100 l0 110 -60 0 -60 0 0 -325z m283 45 c36 -28 50 -81 44 -159
-4 -57 -9 -69 -36 -93 -55 -49 -128 -32 -157 38 -18 44 -18 138 1 174 31 60
99 78 148 40z"/>
<path d="M7500 2910 c0 -60 -3 -110 -6 -110 -3 0 -20 10 -36 22 -42 29 -116
35 -173 14 -141 -54 -167 -316 -42 -428 11 -9 39 -25 63 -34 38 -15 51 -15 87
-5 23 7 56 24 74 37 40 31 43 30 43 -6 0 -30 1 -30 55 -30 l55 0 0 325 0 325
-60 0 -60 0 0 -110z m-47 -170 c57 -45 62 -191 9 -252 -20 -24 -76 -33 -110
-19 -45 19 -62 58 -62 146 0 74 1 78 34 111 41 41 89 46 129 14z"/>
<path d="M5325 2970 c-47 -28 -50 -32 -55 -77 -5 -43 -8 -48 -32 -51 -26 -3
-28 -7 -28 -48 0 -42 1 -44 30 -44 l30 0 0 -145 c0 -172 10 -208 64 -230 42
-18 82 -19 125 -4 30 11 32 15 27 42 -3 16 -6 36 -6 44 0 9 -11 13 -36 11 -22
-1 -39 3 -45 12 -5 8 -8 71 -7 140 l3 125 43 3 42 3 0 44 0 44 -42 3 -43 3 -3
78 c-2 42 -6 77 -10 76 -4 0 -29 -13 -57 -29z"/>
<path d="M8313 2968 c-49 -30 -52 -34 -55 -77 -3 -42 -6 -46 -30 -49 -26 -3
-28 -7 -28 -48 0 -42 1 -44 30 -44 l30 0 0 -147 c0 -179 10 -209 77 -233 36
-12 50 -12 90 -2 26 7 48 14 50 15 1 1 -1 22 -4 46 -5 40 -8 43 -32 38 -14 -3
-34 0 -44 8 -16 11 -17 28 -15 141 l3 129 43 3 42 3 0 44 0 44 -42 3 -43 3 -3
78 c-2 42 -6 77 -10 77 -4 0 -30 -14 -59 -32z"/>
<path d="M11888 2909 c-31 -34 -25 -39 9 -7 l23 21 23 -21 c35 -33 40 -26 5 8
-16 17 -30 29 -31 29 -1 -1 -14 -14 -29 -30z"/>
<path d="M11826 2843 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M12000 2851 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z"/>
<path d="M4025 2827 c-74 -42 -115 -125 -114 -232 0 -103 50 -183 135 -215 68
-25 110 -25 175 -1 51 20 80 45 105 92 l13 26 -55 12 -56 11 -33 -35 c-18 -19
-40 -35 -50 -35 -33 0 -83 31 -93 58 -25 68 -38 62 138 62 l160 0 0 28 c0 50
-30 145 -57 180 -54 71 -186 95 -268 49z m155 -82 c16 -8 31 -24 34 -35 19
-66 27 -60 -78 -60 l-96 0 0 26 c0 34 50 84 85 84 14 0 39 -7 55 -15z"/>
<path d="M4534 2831 c-70 -42 -104 -116 -104 -223 0 -124 63 -215 156 -226 61
-6 107 5 138 33 15 14 29 25 32 25 9 0 4 -112 -6 -130 -13 -24 -79 -43 -117
-34 -15 4 -35 15 -43 25 -14 18 -135 47 -147 35 -2 -2 1 -22 7 -45 18 -69 96
-110 211 -111 103 0 183 45 207 118 9 26 16 332 12 525 0 14 -9 17 -55 17
l-55 0 0 -32 0 -32 -27 26 c-16 14 -38 31 -50 37 -35 18 -124 13 -159 -8z
m196 -102 c25 -31 34 -76 28 -145 -6 -75 -39 -109 -104 -109 -37 0 -50 5 -69
27 -49 57 -41 196 13 238 36 29 103 23 132 -11z"/>
<path d="M5635 2838 c-36 -14 -58 -34 -79 -75 -22 -44 -20 -74 7 -113 30 -46
69 -66 168 -91 95 -24 111 -34 107 -63 -7 -49 -129 -61 -168 -17 -10 13 -20
27 -22 33 -5 15 -118 -1 -118 -17 0 -6 13 -30 29 -52 72 -99 273 -109 362 -17
25 26 33 43 36 81 7 89 -35 126 -190 164 -98 24 -123 40 -107 69 22 41 125 37
159 -6 15 -19 26 -23 45 -19 14 3 37 7 51 10 14 3 25 12 25 20 0 26 -58 81
-98 93 -48 15 -166 14 -207 0z"/>
<path d="M7803 2824 c-36 -24 -81 -89 -70 -101 3 -2 27 -7 56 -12 43 -7 51 -5
56 9 11 36 103 54 138 27 10 -6 19 -25 20 -41 2 -28 -1 -30 -58 -44 -138 -32
-167 -43 -196 -75 -58 -64 -30 -178 51 -211 22 -9 53 -16 69 -16 30 0 116 34
126 50 10 16 21 11 33 -15 10 -22 18 -25 68 -25 54 0 56 1 49 23 -5 12 -11 99
-15 192 -3 94 -10 179 -15 189 -24 55 -77 76 -189 76 -72 0 -87 -3 -123 -26z
m207 -260 c0 -66 -41 -113 -99 -114 -42 0 -71 24 -71 60 0 36 24 55 90 73 80
21 80 21 80 -19z"/>
<path d="M8640 2839 c-14 -6 -36 -20 -48 -32 -26 -24 -56 -76 -49 -83 2 -3 27
-7 54 -11 44 -5 52 -3 66 17 24 34 107 40 136 11 13 -13 21 -32 19 -44 -3 -18
-16 -25 -93 -43 -112 -26 -156 -46 -178 -83 -22 -37 -22 -105 0 -142 20 -34
86 -69 130 -69 34 0 117 32 127 49 11 17 22 13 34 -14 10 -22 18 -25 68 -25
54 0 56 1 49 23 -4 12 -11 101 -14 197 -4 106 -11 183 -19 197 -22 39 -90 63
-178 62 -43 0 -90 -5 -104 -10z m180 -285 c0 -39 -5 -51 -31 -75 -55 -51 -139
-32 -139 32 0 35 25 53 100 73 30 8 58 15 63 15 4 1 7 -20 7 -45z"/>
<path d="M5010 2605 l0 -235 60 0 60 0 0 235 0 235 -60 0 -60 0 0 -235z"/>
<path d="M6072 2651 l3 -189 31 -39 c57 -71 161 -78 240 -18 41 32 44 31 44
-5 0 -30 1 -30 55 -30 l55 0 0 235 0 235 -60 0 -60 0 0 -132 c-1 -207 -18
-248 -106 -248 -70 0 -78 20 -82 217 l-4 163 -60 0 -60 0 4 -189z"/>
<path d="M11427 2622 c-108 -108 -197 -201 -197 -205 0 -4 34 -41 75 -83 41
-42 75 -80 75 -85 0 -5 4 -9 9 -9 4 0 58 -49 120 -110 61 -60 116 -110 122
-110 6 0 99 88 207 196 202 204 206 209 159 238 -13 8 -23 4 -47 -19 -16 -15
-32 -27 -34 -24 -3 2 -64 62 -135 132 -115 114 -129 131 -116 144 8 8 15 18
15 23 0 5 9 12 21 15 20 7 18 10 -22 51 -24 24 -46 44 -49 44 -3 0 -94 -89
-203 -198z"/>
<path d="M11790 2810 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M12037 2809 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M11741 2763 c-7 -8 -11 -16 -8 -19 2 -2 10 2 17 11 17 20 9 27 -9 8z"/>
<path d="M12117 2719 c4 -13 8 -18 11 -10 2 7 -1 18 -6 23 -8 8 -9 4 -5 -13z"/>
<path d="M12123 2655 c0 -22 2 -30 4 -17 2 12 2 30 0 40 -3 9 -5 -1 -4 -23z"/>
<path d="M12096 2595 c-11 -8 -15 -15 -9 -15 6 0 16 7 23 15 16 19 11 19 -14
0z"/>
<path d="M11319 2193 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z"/>
<path d="M11318 1483 c-137 -137 -248 -251 -248 -254 0 -3 101 -106 225 -230
123 -123 225 -228 225 -234 2 -29 80 -84 80 -56 0 6 76 87 170 181 157 158
171 170 192 160 21 -10 21 -10 6 3 -10 8 -18 18 -18 23 0 5 32 40 70 79 l70
71 -250 249 c-138 138 -256 251 -263 253 -6 1 -123 -109 -259 -245z"/>
<path d="M10840 1321 c0 -5 15 -20 33 -32 l32 -22 -29 31 c-32 34 -36 37 -36
23z"/>
<path d="M10910 1256 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"/>
<path d="M10754 1228 l-19 -23 23 19 c21 18 27 26 19 26 -2 0 -12 -10 -23 -22z"/>
<path d="M10950 1216 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"/>
<path d="M10690 1169 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z"/>
<path d="M10995 1160 c10 -11 20 -18 23 -15 7 6 -18 35 -31 35 -5 0 -2 -9 8
-20z"/>
<path d="M10162 967 l-192 -192 48 -48 48 -47 295 0 295 0 47 48 47 48 -190
189 c-104 105 -193 191 -198 193 -4 1 -94 -85 -200 -191z"/>
<path d="M10600 1080 c-13 -11 -21 -22 -18 -25 2 -3 15 6 28 20 28 30 22 33
-10 5z"/>
<path d="M11078 1080 c12 -11 24 -20 28 -20 4 0 -1 9 -11 20 -10 11 -23 20
-28 20 -6 0 -1 -9 11 -20z"/>
<path d="M11064 1018 l-29 -33 33 29 c30 28 37 36 29 36 -2 0 -16 -15 -33 -32z"/>
<path d="M10606 1027 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M12000 1011 c0 -9 49 -56 53 -50 1 2 -10 16 -25 31 -15 15 -28 23
-28 19z"/>
<path d="M11010 979 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M10670 967 c0 -2 14 -16 31 -33 26 -24 30 -25 20 -7 -10 19 -51 51
-51 40z"/>
<path d="M10979 933 l-24 -28 28 24 c15 14 27 26 27 28 0 8 -8 1 -31 -24z"/>
<path d="M12097 910 c15 -17 23 -42 26 -80 l3 -55 2 56 c2 49 -1 58 -25 80
l-28 24 22 -25z"/>
<path d="M10750 886 c0 -2 7 -9 15 -16 9 -7 15 -8 15 -2 0 5 -7 12 -15 16 -8
3 -15 4 -15 2z"/>
<path d="M10909 863 c-13 -16 -12 -17 4 -4 16 13 21 21 13 21 -2 0 -10 -8 -17
-17z"/>
<path d="M12122 710 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
